import React from 'react'
import { ApolloProvider } from 'react-apollo'
import mixpanel from 'mixpanel-browser'
import { client } from './client'

export const MixpanelContext = React.createContext()

export const wrapRootElement = ({ element }) => {
  mixpanel.init('1e2fab759c4dcb54aec7d258dc77a278', { debug: true })

  return (
    <ApolloProvider client={client}>
      <MixpanelContext.Provider value={mixpanel}>
        {element}
      </MixpanelContext.Provider>
    </ApolloProvider>
  )
}
