/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export { wrapRootElement } from './src/apollo/wrap-root-element'

window.BACKEND_URL = `https://api${
  window.location.hostname.includes('stage') ? '-stage' : ''
}.santiment.net`
window.GQL_SERVER_URL = window.BACKEND_URL + '/graphql'

const activateOptimize = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'optimize.activate' })
}

export const onRouteUpdate = () => {
  activateOptimize()
}
