// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("/app/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-email-login-js": () => import("/app/src/pages/email_login.js" /* webpackChunkName: "component---src-pages-email-login-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-email-js": () => import("/app/src/pages/login/email.js" /* webpackChunkName: "component---src-pages-login-email-js" */),
  "component---src-pages-login-index-js": () => import("/app/src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-sign-up-index-js": () => import("/app/src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/app/.cache/data.json")

